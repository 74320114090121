import { faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Navbar,
  Row,
} from 'react-bootstrap';
import {
  addProduct,
  bookAppointment,
  cancelAppointment,
  createFNOL,
  getAppointments,
  getBookedAppointment,
  getCancelReasons,
  getContractDetails,
  getDispatchMessageList,
  getFailureTypes,
  getManufacturers,
  getServiceOrders,
  getSubcategories,
  rebookAppointment,
  updateProduct,
} from '../api/serviceavenger';
import logo from '../img/ServiceAvengerLogo.png';
import './App.css';
import AppointmentList from './AppointmentList';
import Contract from './Contract';
import ContractList from './ContractList';
import ContractModal from './ContractModal';
import Customer from './Customer';
import ExistingAppointment from './ExistingAppointment';
import LossCodeList from './LossCodeList';
import Product from './Product';
import ProductList from './ProductList';
import Service from './Service';
import ServiceOrderList from './ServiceOrderList';

class App extends Component {
  state = {
    address: '',
    address2: '',
    appointmentWorking: false,
    appointmentList: [],
    beginSearchDate: moment().format('MM/DD/YYYY'), // default to today's date then let did mount set the begin date.
    bookingType: 'Standard',
    brand: '', // mfg
    cancelReasonCode: '',
    cancelReasons: [],
    city: '',
    claimReimbursementMethod: '',
    condition: 'O', // default to O
    contextType: '',
    contractList: [],
    contractNumber: '', // onpoint contract number
    contractStatus: '',
    contractStatusCode: '',
    contractType: 'Service Contract', // default to Service Contract
    conversationId: '',
    countryCode: 'USA',
    coverageProductTypeCodeStr: '',
    customerId: '',
    dealerCustomerId: '',
    dealerName: '', // dealer name string
    deductible: 0,
    deductibleTerm: '',
    dispatchMessage: '',
    dispatchMessageList: [],
    effectiveDt: '', // contract begin date
    email: '',
    errorMessage: '',
    existingAppointment: null,
    expirationDt: '', // contract end date
    externalContractNumber: '',
    failureDt: moment(new Date()).format('MM/DD/YYYY'),
    failureNote: '',
    failureType: 'Standard',
    forcedAppointment: {
      appointmentInfo: {
        appointmentBrand: 'UNKNO',
        appointmentProduct: 'UNSPEC',
        sortOrder: 1,
        clientKey: 'I895',
        svcrName: 'ONPOINT TEST SERVICER',
        serviceCenterID: 'ONPOINT',
        svcrStatus: 'PHONE',
        svcrAddress1: '9900 CORPORATE CAMPUS DR  ',
        svcrAddress2: {
          $: {
            'xsi:nil': 'true',
          },
        },
        svcrCity: 'LOUISVILLE',
        svcrState: 'KENTUCKY',
        svcrZipcode: '40223',
        svcrPhone: '8776684681',
        svcrCountry: {
          $: {
            'xsi:nil': 'true',
          },
        },
        svcrOEMAuthFlag: 'NO',
        svcrRanking: '25.0',
        svcrType: 'PHONE',
        timeSlotData: {
          svcrID: '00000',
          offerID: '',
          availableDate: moment().format('MM/DD/YYYY'),
          timePeriod: '8-17',
          timePeriodSlotDescription: '',
          timePeriodSlot: '',
        },
      },
    },
    hasConcessionFlg: false,
    symptomCode: '',
    symptomCodeList: [],
    filteredProductList: [],
    firstName: '',
    functionalityStatus: 'Functional',
    isBrandValidated: false,
    isBrandValid: null,
    isCancelReasonValidated: false,
    isCancelReasonValid: null,
    isEffective: false,
    isFailureNoteValidated: false,
    isFailureNoteValid: null,
    isModelValidated: false,
    isModelValid: null,
    isPostalCodeValidated: false,
    isPostalCodeValid: null,
    isSubcategoryValidated: false,
    isSubcategoryValid: null,
    isSerialValidated: false,
    isSerialValid: null,
    isSymptomCodeValidated: false,
    isSymptomCodeValid: null,
    kustomerEmail: '',
    kustomerPhone: '',
    lastName: '',
    loading: false,
    lolAvailable: '',
    lolUsed: '',
    lossCodeList: [],
    manufacturers: [],
    model: '', // model number
    newBrand: '', //mfg
    newContract: {},
    newModel: '', // model number
    newProductCode: '', // subcategory code
    newProductTierCode: '',
    newSubcategory: '', // subcategory
    newSerial: '', // serial
    offerId: '',
    orgid: '',
    postalCode: '',
    primaryPhone: '',
    procedureCode: 'Field Service',
    productCode: '', //subcategory code
    productTierCode: '',
    purchasePrice: '',
    secondaryPhone: '',
    selectedAppointment: null,
    selectedContract: null,
    selectedLossCode: null,
    selectedProduct: null,
    selectedServiceOrder: null,
    sequenceNumber: '',
    serial: '', // serial
    serviceElevation: 'Normal',
    serviceLocation: 'In Home',
    serviceNotice: 'First Notice',
    servicerNotes: '',
    serviceOrderClaimStatus: '',
    serviceOrderList: [],
    serviceOrderNumber: '',
    serviceType: 'Repair',
    showAppointmentList: false,
    showBookSuccess: false,
    showCancelSuccess: false,
    showDispatchMessageModal: false,
    showFNOLSuccess: false,
    state: '',
    subCategory: '',
    subcategories: [],
    tierCode: '',
    totalLiability: '',
    unfilteredProductList: [],
    warrantyType: 'Service Contract',
  };

  componentDidMount = async () => {
    if (window.Kustomer) {
      window.Kustomer.initialize((context) => {
        // Leave this for troubleshooting issues with Kustomer object
        console.log('Beginning of component did mount. context: ', context);
        this.setState({ contextType: context.type });
        if (context.type !== 'widget') {
          const { customer, company } = context;
          const existingPhones = customer.attributes.phones;
          const existingEmails = customer.attributes.emails;
          let phone = '';
          let email = '';
          if (Object.keys(existingPhones).length > 0) {
            let phoneString = existingPhones[0].phone;
            phoneString = phoneString.replace(/\D/g, '');
            phone = phoneString.replace('+', '');
            if (phone.length > 10) {
              phone = phone.substring(phone.length - 10);
            }
          }
          if (Object.keys(existingEmails).length > 0) {
            email = existingEmails[0].email;
          }

          this.setState({
            businessName: company,
            customerId: customer.id,
            kustomerEmail: email,
            kustomerPhone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            orgid: customer.relationships.org.data.id,
          });
        }
        if (context.type === 'conversation') {
          const { conversation, customer } = context;
          const existingPhones = customer.attributes.phones;
          const existingEmails = customer.attributes.emails;
          let phone = '';
          let email = '';
          if (Object.keys(existingPhones).length > 0) {
            let phoneString = existingPhones[0].phone;
            phoneString = phoneString.replace(/\D/g, '');
            phone = phoneString.replace('+', '');
            if (phone.length > 10) {
              phone = phone.substring(phone.length - 10);
            }
          }
          if (Object.keys(existingEmails).length > 0) {
            email = existingEmails[0].email;
          }

          this.setState({
            conversationId: conversation.id,
            kustomerEmail: email,
            kustomerPhone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            orgid: customer.relationships.org.data.id,
          });
        }
        this.getContractList();
        this.setBeginDate();
        this.getSubcategories();
        this.getManufacturers();
        this.getCancelReasons();
        this.getDispatchMessageList();
      });
    }
    // Leave this for troubleshooting issues with Kustomer object
    console.log('End of component did mount.');
  };

  handleDepotBookAppointment = async () => {
    let errorMessage = '';
    const {
      address,
      address2,
      brandCode,
      businessName,
      city,
      contractNumber,
      countryCode,
      customerId,
      effectiveDt,
      email,
      expirationDt,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      installDt,
      lastName,
      model,
      postalCode,
      primaryPhone,
      productCode,
      productTierCode,
      secondaryPhone,
      selectedAppointment,
      sequenceNumber,
      serial,
      serviceElevation,
      serviceLocation,
      serviceOrderNumber,
      servicerNotes,
      serviceType,
      state,
      symptomCode,
      warrantyType,
    } = this.state;

    if (this.ValidateBookAppointment()) {
      try {
        this.setState({ appointmentWorking: true });
        const response = await bookAppointment(
          'Forced',
          firstName,
          lastName,
          businessName,
          address,
          address2,
          city,
          state,
          postalCode,
          primaryPhone,
          secondaryPhone,
          email,
          countryCode,
          customerId,
          sequenceNumber,
          brandCode,
          productCode,
          productTierCode,
          model,
          serial,
          warrantyType,
          effectiveDt,
          expirationDt,
          contractNumber,
          externalContractNumber,
          failureDt,
          serviceType,
          serviceElevation,
          serviceOrderNumber,
          serviceLocation,
          failureType,
          functionalityStatus,
          symptomCode,
          failureNote,
          selectedAppointment,
          servicerNotes,
          installDt
        );

        if (response.status === 200) {
          if (response.data.status !== 200) {
            const { data } = response;
            errorMessage += `<br/><b>Error while attempting to book depot appointment:</b><br/>${data.status} - ${data.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>';
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              } else {
                errorDetails = data?.data?.data?.details;
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              }
            }

            this.setState({ errorMessage, appointmentWorking: false });
            window.location.href = '#errorFound';
          } else {
            const newServiceOrderNumber = response.data.data.callNumber;
            const newServiceOrderList = await this.getServiceOrders(
              contractNumber
            );
            const newServiceOrderResults = newServiceOrderList.filter(
              (serviceorder) =>
                serviceorder.serviceOrderNumber === newServiceOrderNumber
            );
            const newServiceOrder =
              newServiceOrderResults.length > 0
                ? newServiceOrderResults[0]
                : null;

            await this.getAppointment(contractNumber, newServiceOrderNumber);
            this.setState({
              appointmentWorking: false,
              selectedServiceOrder: newServiceOrder,
              serviceOrderNumber: newServiceOrderNumber,
              showAppointmentList: false,
              selectedAppointment: null,
              showBookSuccess: true,
            });

            window.location.href = '#existingAppointment';

            setTimeout(() => {
              this.setState({
                showBookSuccess: false,
              });
            }, 3000);
          }
        } else {
          errorMessage += `<br/><b>Error attempting to book depot appointment:</b><br/>${response.status} - ${response.message}`;
          this.setState({ errorMessage, appointmentWorking: false });
          window.location.href = '#errorFound';
        }
      } catch (error) {
        this.setState({
          errorMessage: `<br/><b>Error when attempting to book depot appointment:</b><br/>${error.message}`,
          appointmentWorking: false,
        });
        window.location.href = '#errorFound';
      }
    }
  };

  handleForceBookAppointment = async () => {
    if (window.confirm('Are you sure you want to force book to OnPoint?')) {
      let errorMessage = '';
      const {
        address,
        address2,
        brandCode,
        businessName,
        city,
        contractNumber,
        countryCode,
        customerId,
        effectiveDt,
        email,
        expirationDt,
        // existingAppointment,
        externalContractNumber,
        failureDt,
        failureNote,
        failureType,
        firstName,
        forcedAppointment,
        functionalityStatus,
        installDt,
        lastName,
        model,
        postalCode,
        primaryPhone,
        productCode,
        productTierCode,
        secondaryPhone,
        sequenceNumber,
        serial,
        serviceElevation,
        serviceLocation,
        serviceOrderNumber,
        servicerNotes,
        serviceType,
        state,
        symptomCode,
        warrantyType,
      } = this.state;

      if (this.ValidateBookAppointment()) {
        // 12/02/2021 Issue with rebooking with a forced appt.  Commenting out until we can work out the kinks.
        // Workaround: cancel the existing appointment.  Create a new service order and force book appointment.
        // if (existingAppointment != null) {
        //   try {
        //     this.setState({ appointmentWorking: true });
        //     const response = await rebookAppointment(
        //       contractNumber,
        //       externalContractNumber,
        //       serviceOrderNumber,
        //       customerId,
        //       firstName,
        //       lastName,
        //       primaryPhone,
        //       sequenceNumber,
        //       forcedAppointment,
        //       serviceType,
        //       "Forced",
        //       serviceLocation
        //     );

        //     if (response.status === 200) {
        //       if (response.data.status !== 200) {
        //         const { data } = response;
        //         errorMessage += `<b>Error while attempting to re-book forced appointment:</b><br/>${data?.status} - ${data?.message}`;
        //         let errorDetails = data.data.details;

        //         if (errorDetails?.length > 0) {
        //           errorMessage += "<ul>";
        //           errorDetails.forEach((error) => {
        //             errorMessage += `<li>${error.code} - ${error.message}</li>`;
        //           });
        //           errorMessage += "</ul>";
        //         }

        //         let bookingMessage = data.data.bookingMessage;
        //         errorMessage +=
        //           bookingMessage?.length > 0 ? bookingMessage : null;

        //         this.setState({ errorMessage, appointmentWorking: false });
        //         window.location.href = "#errorFound";
        //       } else {
        //         const newServiceOrderNumber = response.data.data.callNumber;
        //         const newServiceOrderList = await this.getServiceOrders(
        //           contractNumber
        //         );
        //         const newServiceOrderResults = newServiceOrderList.filter(
        //           (serviceorder) =>
        //             serviceorder.serviceOrderNumber === newServiceOrderNumber
        //         );
        //         const newServiceOrder =
        //           newServiceOrderResults.length > 0
        //             ? newServiceOrderResults[0]
        //             : null;

        //         await this.getAppointment(
        //           contractNumber,
        //           newServiceOrderNumber
        //         );
        //         this.setState({
        //           appointmentWorking: false,
        //           selectedServiceOrder: newServiceOrder,
        //           serviceOrderNumber: newServiceOrderNumber,
        //           showAppointmentList: false,
        //           selectedAppointment: null,
        //         });
        //       }
        //     } else {
        //       errorMessage += `<br/><b>Error attempting to re-book forced appointment:</b><br/>${response.status} - ${response.message}`;
        //       this.setState({ errorMessage, appointmentWorking: false });
        //       window.location.href = "#errorFound";
        //     }
        //   } catch (error) {
        //     this.setState({
        //       errorMessage: `<br/><b>Error attempting to force re-book appointment:</b><br/>${error.message}`,
        //       appointmentWorking: false,
        //     });
        //     window.location.href = "#errorFound";
        //   }
        // } else {
        try {
          this.setState({ appointmentWorking: true });
          const response = await bookAppointment(
            'Forced',
            firstName,
            lastName,
            businessName,
            address,
            address2,
            city,
            state,
            postalCode,
            primaryPhone,
            secondaryPhone,
            email,
            countryCode,
            customerId,
            sequenceNumber,
            brandCode,
            productCode,
            productTierCode,
            model,
            serial,
            warrantyType,
            effectiveDt,
            expirationDt,
            contractNumber,
            externalContractNumber,
            failureDt,
            serviceType,
            serviceElevation,
            serviceOrderNumber,
            serviceLocation,
            failureType,
            functionalityStatus,
            symptomCode,
            failureNote,
            forcedAppointment,
            servicerNotes,
            installDt
          );

          if (response.status === 200) {
            if (response.data.status !== 200) {
              const { data } = response;
              errorMessage += `<br/><b>Error while attempting to force book appointment:</b><br/>${data.status} - ${data.message}`;
              let errorDetails = data?.data?.details;

              if (errorDetails?.length > 0) {
                errorMessage += '<ul>';
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
                });
                errorMessage += '</ul>';
              } else {
                errorDetails = data?.data;
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>';
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[
                        key
                      ].toString()}</li>`;
                    });
                    errorMessage += '</ul>';
                  });
                } else {
                  errorMessage += `<ul>${data.data.bookingIndicator} - ${data.data.bookingMessage}</ul>`;
                }
              }

              this.setState({ errorMessage, appointmentWorking: false });
              window.location.href = '#errorFound';
            } else {
              const newServiceOrderNumber = response.data.data.callNumber;
              const newServiceOrderList = await this.getServiceOrders(
                contractNumber
              );
              const newServiceOrderResults = newServiceOrderList.filter(
                (serviceorder) =>
                  serviceorder.serviceOrderNumber === newServiceOrderNumber
              );
              const newServiceOrder =
                newServiceOrderResults.length > 0
                  ? newServiceOrderResults[0]
                  : null;

              await this.getAppointment(contractNumber, newServiceOrderNumber);
              this.setState({
                appointmentWorking: false,
                selectedServiceOrder: newServiceOrder,
                serviceOrderNumber: newServiceOrderNumber,
                showAppointmentList: false,
                selectedAppointment: null,
                showBookSuccess: true,
              });

              window.location.href = '#existingAppointment';

              setTimeout(() => {
                this.setState({
                  showBookSuccess: false,
                });
              }, 3000);
            }
          } else {
            errorMessage += `<br/><b>Error attempting to force book appointment:</b><br/>${response.status} - ${response.message}`;
            this.setState({ errorMessage, appointmentWorking: false });
            window.location.href = '#errorFound';
          }
        } catch (error) {
          this.setState({
            errorMessage: `<br/><b>Error when attempting to force book appointment:</b><br/>${error.message}`,
            appointmentWorking: false,
          });
          window.location.href = '#errorFound';
        }
        // }
      }
    }
  };

  handleBookAppointment = async () => {
    let errorMessage = '';
    const {
      address,
      address2,
      bookingType,
      brandCode,
      businessName,
      city,
      contractNumber,
      countryCode,
      customerId,
      effectiveDt,
      email,
      expirationDt,
      existingAppointment,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      installDt,
      lastName,
      model,
      postalCode,
      primaryPhone,
      productCode,
      productTierCode,
      secondaryPhone,
      selectedAppointment,
      sequenceNumber,
      serial,
      serviceElevation,
      serviceLocation,
      serviceOrderNumber,
      servicerNotes,
      serviceType,
      state,
      symptomCode,
      warrantyType,
    } = this.state;

    if (this.ValidateBookAppointment()) {
      if (existingAppointment != null) {
        if (
          window.confirm(
            `There is an existing appointment on ${moment
              .utc(existingAppointment.appointmentDate)
              .format('MM/DD/YYYY')} from ${existingAppointment.appointmentTimeSlot
            }.
        Are you sure you wish to change to ${moment
              .utc(selectedAppointment.appointmentInfo.timeSlotData.availableDate)
              .format('MM/DD/YYYY')} from ${selectedAppointment.appointmentInfo.timeSlotData.timePeriod
            }?`
          )
        ) {
          try {
            this.setState({ appointmentWorking: true });
            const response = await rebookAppointment(
              bookingType,
              firstName,
              lastName,
              businessName,
              address,
              address2,
              city,
              state,
              postalCode,
              primaryPhone,
              secondaryPhone,
              email,
              countryCode,
              customerId,
              sequenceNumber,
              brandCode,
              productCode,
              productTierCode,
              model,
              serial,
              warrantyType,
              effectiveDt,
              expirationDt,
              contractNumber,
              externalContractNumber,
              failureDt,
              serviceType,
              serviceElevation,
              serviceOrderNumber,
              serviceLocation,
              failureType,
              functionalityStatus,
              symptomCode,
              failureNote,
              selectedAppointment,
              servicerNotes,
              installDt
            );

            if (response.status === 200) {
              if (response.data.status !== 200) {
                const { data } = response;
                errorMessage += `<b>Error while attempting to re-book:</b><br/>${data?.status} - ${data?.message}`;
                let errorDetails = data?.data?.details;

                if (errorDetails?.length > 0) {
                  errorMessage += '<ul>';
                  errorDetails.forEach((error) => {
                    errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
                  });
                  errorMessage += '</ul>';
                } else {
                  errorDetails = data?.data;
                  if (errorDetails.length > 0) {
                    errorDetails?.forEach((error) => {
                      errorMessage += '<ul>';
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[
                          key
                        ].toString()}</li>`;
                      });
                      errorMessage += '</ul>';
                    });
                  } else {
                    errorDetails = data?.data?.data?.details;
                    errorDetails?.forEach((error) => {
                      errorMessage += '<ul>';
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[
                          key
                        ].toString()}</li>`;
                      });
                      errorMessage += '</ul>';
                    });
                  }
                }

                this.setState({ errorMessage, appointmentWorking: false });
                window.location.href = '#errorFound';
              } else {
                const newServiceOrderNumber = response.data.data.callNumber;
                const newServiceOrderList = await this.getServiceOrders(
                  contractNumber
                );
                const newServiceOrderResults = newServiceOrderList.filter(
                  (serviceorder) =>
                    serviceorder.serviceOrderNumber === newServiceOrderNumber
                );
                const newServiceOrder =
                  newServiceOrderResults.length > 0
                    ? newServiceOrderResults[0]
                    : null;

                await this.getAppointment(
                  contractNumber,
                  newServiceOrderNumber
                );
                this.setState({
                  appointmentWorking: false,
                  selectedServiceOrder: newServiceOrder,
                  serviceOrderNumber: newServiceOrderNumber,
                  showAppointmentList: false,
                  selectedAppointment: null,
                  showBookSuccess: true,
                });

                window.location.href = '#existingAppointment';

                setTimeout(() => {
                  this.setState({
                    showBookSuccess: false,
                  });
                }, 3000);
              }
            } else {
              errorMessage += `<br/><b>Error attempting to re-book appointment:</b><br/>${response.status} - ${response.message}`;
              this.setState({ errorMessage, appointmentWorking: false });
              window.location.href = '#errorFound';
            }
          } catch (error) {
            this.setState({
              errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${error.message}`,
              appointmentWorking: false,
            });
            window.location.href = '#errorFound';
          }
        }
      } else {
        try {
          this.setState({ appointmentWorking: true });
          const response = await bookAppointment(
            bookingType,
            firstName,
            lastName,
            businessName,
            address,
            address2,
            city,
            state,
            postalCode,
            primaryPhone,
            secondaryPhone,
            email,
            countryCode,
            customerId,
            sequenceNumber,
            brandCode,
            productCode,
            productTierCode,
            model,
            serial,
            warrantyType,
            effectiveDt,
            expirationDt,
            contractNumber,
            externalContractNumber,
            failureDt,
            serviceType,
            serviceElevation,
            serviceOrderNumber,
            serviceLocation,
            failureType,
            functionalityStatus,
            symptomCode,
            failureNote,
            selectedAppointment,
            servicerNotes,
            installDt
          );

          if (response.status === 200) {
            if (response.data.status !== 200) {
              const { data } = response;
              errorMessage += `<br/><b>Error attempting to book appointment:</b><br/>${data.status} - ${data.message}`;

              let errorDetails = data?.data?.details;

              if (errorDetails?.length > 0) {
                errorMessage += '<ul>';
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
                });
                errorMessage += '</ul>';
              } else {
                errorDetails = data?.data;
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>';
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[
                        key
                      ].toString()}</li>`;
                    });
                    errorMessage += '</ul>';
                  });
                } else {
                  errorDetails = data?.data?.data?.details;
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>';
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[
                        key
                      ].toString()}</li>`;
                    });
                    errorMessage += '</ul>';
                  });
                }
              }

              this.setState({ errorMessage, appointmentWorking: false });
              window.location.href = '#errorFound';
            } else {
              const newServiceOrderNumber = response.data.data.callNumber;
              const newServiceOrderList = await this.getServiceOrders(
                contractNumber
              );
              const newServiceOrderResults = newServiceOrderList.filter(
                (serviceorder) =>
                  serviceorder.serviceOrderNumber === newServiceOrderNumber
              );
              const newServiceOrder =
                newServiceOrderResults.length > 0
                  ? newServiceOrderResults[0]
                  : null;

              await this.getAppointment(contractNumber, newServiceOrderNumber);
              this.setState({
                appointmentWorking: false,
                serviceOrderList: newServiceOrderList,
                selectedServiceOrder: newServiceOrder,
                serviceOrderNumber: newServiceOrderNumber,
                showAppointmentList: false,
                selectedAppointment: null,
                showBookSuccess: true,
              });

              window.location.href = '#existingAppointment';

              setTimeout(() => {
                this.setState({
                  showBookSuccess: false,
                });
              }, 3000);
            }
          } else {
            errorMessage += `<br/><b>Error attempting to book appointment:</b><br/>${response.status} - ${response.message}`;
            this.setState({ errorMessage, appointmentWorking: false });
            window.location.href = '#errorFound';
          }
        } catch (error) {
          this.setState({
            errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${error.message}`,
            appointmentWorking: false,
          });
          window.location.href = '#errorFound';
        }
      }
    }
  };

  ValidateBookAppointment = () => {
    const { contractStatusCode, selectedServiceOrder, sequenceNumber } =
      this.state;
    if (selectedServiceOrder != null) {
      if (
        selectedServiceOrder.productSequenceNumber !== sequenceNumber.toString()
      ) {
        alert('The selected service order is not for the selected product.');
        return false;
      } else {
        return true;
      }
    } else {
      if (contractStatusCode !== 'A') {
        alert(
          'You cannot create a new service order for a contract that is not active.'
        );
        return false;
      } else {
        return true;
      }
    }
  };

  handleAddProduct = async () => {
    let errorMessage = '';
    const {
      address,
      address2,
      city,
      condition,
      contractNumber,
      countryCode,
      dealerCustomerId,
      email,
      externalContractNumber,
      firstName,
      installDt,
      lastName,
      newBrand,
      newModel,
      newSubcategory,
      newSerial,
      postalCode,
      primaryPhone,
      purchaseDt,
      secondaryPhone,
      serviceLocation,
      state,
      unfilteredProductList,
    } = this.state;

    let maxSequence = Math.max.apply(
      Math,
      unfilteredProductList.map(function (product) {
        return product.productSequenceNumber;
      })
    );
    maxSequence++;

    try {
      const response = await addProduct(
        externalContractNumber,
        contractNumber,
        dealerCustomerId,
        firstName,
        lastName,
        address,
        address2,
        city,
        state,
        postalCode,
        primaryPhone,
        secondaryPhone,
        email,
        countryCode,
        maxSequence,
        newSubcategory,
        newBrand,
        newModel,
        newSerial,
        condition,
        serviceLocation,
        installDt,
        purchaseDt
      );

      if (response.status === 200) {
        if (response.data.status !== 200) {
          const { data } = response;
          errorMessage += `<b>Error while adding product:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage, appointmentWorking: false });
          window.location.href = '#errorFound';
          return null;
        } else {
          const contractDetails = await this.getContractDetails(contractNumber);
          const newProductList = contractDetails.product;
          const newProductSearch = newProductList.filter(
            (product) => product.productSequenceNumber === maxSequence
          );
          const newProduct =
            newProductSearch.length > 0 ? newProductSearch[0] : null;

          this.setState({
            unfilteredProductList: newProductList,
          });
          if (
            this.state.selectedLossCode != null &&
            this.state.selectedLossCode !== ''
          ) {
            this.handleLossCodeSelect(this.state.selectedLossCode);
          }
          if (newProduct != null) {
            this.handleProductSelect(newProduct);
          }
        }
      } else {
        errorMessage += `<br/><b>Error attempting to add product:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error attempting to add product:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  handleCancelAppointment = async () => {
    let errorMessage = '';

    if (this.validateCancel()) {
      try {
        this.setState({ appointmentWorking: true, errorMessage: '' });

        const {
          cancelReasonCode,
          contractNumber,
          customerId,
          existingAppointment,
          serviceOrderNumber,
        } = this.state;

        const response = await cancelAppointment(
          cancelReasonCode,
          customerId,
          existingAppointment
        );

        if (response.status === 200) {
          if (response.data?.status !== 200) {
            const { data } = response;
            errorMessage += `<b>Error while attempting to cancel:</b><br/>${data?.status} - ${data?.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>';
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              } else {
                errorDetails = data?.data?.data?.details;
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              }
            }

            this.setState({ errorMessage, appointmentWorking: false });
            window.location.href = '#errorFound';
          } else {
            const updatedAppointment = await getBookedAppointment(
              contractNumber,
              serviceOrderNumber
            );

            this.setState({
              existingAppointment: updatedAppointment.data.data[0],
            });

            const updatedServiceOrderList = await this.getServiceOrders(
              contractNumber
            );

            const updatedServiceOrderResults = updatedServiceOrderList.filter(
              (serviceorder) =>
                serviceorder.serviceOrderNumber === serviceOrderNumber
            );

            const updatedServiceOrder =
              updatedServiceOrderResults.length > 0
                ? updatedServiceOrderResults[0]
                : null;

            this.setState({
              cancelReasonCode: '',
              appointmentWorking: false,
              selectedAppointment: null,
              selectedServiceOrder: updatedServiceOrder,
              serviceOrderList: updatedServiceOrderList,
              showCancelSuccess: true,
            });

            window.location.href = '#existingAppointment';

            setTimeout(() => {
              this.setState({
                showCancelSuccess: false,
              });
            }, 3000);
          }
        } else {
          errorMessage += `<br/><b>Error before attempting to cancel appointment:</b><br/>${response.status} - ${response.message}`;
          this.setState({ errorMessage, appointmentWorking: false });
          window.location.href = '#errorFound';
        }
      } catch (error) {
        this.setState({
          errorMessage: `<br/><b>Error attempting to cancel appointment:</b><br/>${error.message}`,
          appointmentWorking: false,
        });
        window.location.href = '#errorFound';
      }
    }
  };

  handleResetProduct = async () => {
    const { manufacturers, subcategories, selectedProduct } = this.state;
    const { mfg, modelNumber, serial, subCategory } = selectedProduct;

    const brandDetails = manufacturers.find(function (brandDetail) {
      return brandDetail.brand.toUpperCase() === mfg.toUpperCase();
    });

    const subcategoryDetails = subcategories.find(function (subcategory) {
      return (
        subcategory.subCatDescription.toUpperCase() ===
        subCategory.toUpperCase()
      );
    });

    this.setState({
      brand: mfg,
      brandCode: brandDetails.brandCode,
      model: modelNumber,
      productCode: subcategoryDetails.subCatCode,
      productTierCode: subcategoryDetails.tierCode,
      subCategory,
      serial,
    });
  };

  handleCreateFNOL = async () => {
    this.setState({ loading: true });

    let errorMessage = '';
    const {
      address,
      address2,
      city,
      contractNumber,
      countryCode,
      customerId,
      deductible,
      email,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      hasConcessionFlg,
      lastName,
      primaryPhone,
      sequenceNumber,
      serviceElevation,
      servicerNotes,
      serviceNotice,
      serviceType,
      state,
      symptomCode,
      postalCode,
    } = this.state;

    if (this.validatePage()) {
      try {
        const response = await createFNOL(
          address,
          address2,
          city,
          countryCode,
          deductible,
          failureNote,
          failureType,
          functionalityStatus,
          serviceElevation,
          symptomCode,
          contractNumber,
          customerId,
          email,
          externalContractNumber,
          failureDt,
          firstName,
          hasConcessionFlg,
          lastName,
          primaryPhone,
          sequenceNumber,
          serviceNotice,
          servicerNotes,
          serviceType,
          state,
          postalCode
        );

        if (response.status === 200) {
          const newServiceOrderNumber = response.data.data.claimNumber;
          const newServiceOrderList = await this.getServiceOrders(
            contractNumber
          );
          const newServiceOrderResults = newServiceOrderList.filter(
            (serviceorder) =>
              serviceorder.serviceOrderNumber === newServiceOrderNumber
          );
          const newServiceOrder =
            newServiceOrderResults.length > 0
              ? newServiceOrderResults[0]
              : null;
          await this.getAppointment(contractNumber, newServiceOrderNumber);
          this.setState({
            appointmentWorking: false,
            claimReimbursementMethod: newServiceOrder.claimReimbursementMethod,
            loading: false,
            serviceOrderList: newServiceOrderList,
            selectedServiceOrder: newServiceOrder,
            serviceOrderClaimStatus: newServiceOrder.claimStatus,
            serviceOrderNumber: newServiceOrderNumber,
            showAppointmentList: false,
            selectedAppointment: null,
            showFNOLSuccess: true,
          });
          window.location.href = '#existingAppointment';
          setTimeout(() => {
            this.setState({
              showFNOLSuccess: false,
            });
          }, 5000);
        } else {
          errorMessage += `<br/><b>Error creating FNOL:</b><br/>${response.status} - ${response.message}`;
          this.setState({ errorMessage, loading: false });
          window.location.href = '#errorFound';
        }
      } catch (error) {
        this.setState({
          loading: false,
          errorMessage: `<br/><b>Error attempting to create FNOL:</b><br/>${error.message}`,
        });
        window.location.href = '#errorFound';
      }
    } else {
      this.setState({ loading: false });
    }
  };

  handleUpdateProduct = async () => {
    this.setState({ loading: true });

    let errorMessage = '';
    const {
      contractNumber,
      dealerCustomerId,
      externalContractNumber,
      sequenceNumber,
      productCode,
      brand,
      manufacturers,
      model,
      serial,
      selectedProduct,
      serviceLocation,
    } = this.state;

    try {
      const response = await updateProduct(
        contractNumber,
        dealerCustomerId,
        externalContractNumber,
        sequenceNumber,
        productCode,
        brand,
        model,
        serial,
        serviceLocation
      );

      this.setState({ loading: false });

      if (response.status === 200) {
        if (response.data.status !== 200) {
          const { data } = response;
          errorMessage += `<b>Error while updating product:</b><br/>`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          const brandDetails = manufacturers.find(function (brandDetail) {
            return (
              brandDetail.brand.toUpperCase() ===
              selectedProduct.mfg.toUpperCase()
            );
          });

          window.location.href = '#errorFound';
          this.setState({
            brand: selectedProduct.mfg.toUpperCase(),
            brandCode: brandDetails.brandCode,
            errorMessage,
            model: selectedProduct.modelNumber,
            serial: selectedProduct.serial,
          });
          return null;
        } else {
          const updatedProduct = response.data.data.productDetails[0];
          selectedProduct.mfg = updatedProduct.manufacturer;
          selectedProduct.modelNumber = updatedProduct.modelNumber;
          selectedProduct.serial = updatedProduct.serialNumber;

          const newProductList = await this.getContractDetails(contractNumber);

          this.setState({
            selectedProduct,
            unfilteredProductList: newProductList.product,
          });
        }
      } else {
        errorMessage += `<br/><b>Error attempting to update product:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error attempting to update product:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  setBeginDate() {
    const { beginSearchDate } = this.state;

    let dayOfWeek = moment(beginSearchDate).day();
    if (dayOfWeek < 1) {
      this.setState({
        beginSearchDate: moment(beginSearchDate)
          .add(1, 'days')
          .format('MM/DD/YYYY'),
      });
    }
    if (dayOfWeek > 1) {
      let daysToSubtract = dayOfWeek - 1;
      this.setState({
        beginSearchDate: moment(beginSearchDate)
          .add(daysToSubtract * -1, 'days')
          .format('MM/DD/YYYY'),
      });
    }
  }

  getContractList = async () => {
    // Leave this for troubleshooting issues with Kustomer object
    console.log(
      'Get contract url:',
      ` /v1/customers/${this.state.customerId}/klasses/contract?pageSize=5000`
    );
    try {
      const responseObj = await window.Kustomer.request({
        url: `/v1/customers/${this.state.customerId}/klasses/contract?pageSize=5000`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
      // Leave this for troubleshooting issues with Kustomer object
      console.log('Kustomer contract list response object: ', responseObj);
      this.setState({ contractList: responseObj });
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error when getting contract list from Kustomer:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getServiceAppointments = async (beginDt) => {
    try {
      let errorMessage = this.state.errorMessage;
      let appointments = [];
      let selectedAppointment = null;

      if (this.validatePage()) {
        this.setState({ loading: true });
        const {
          address,
          address2,
          beginSearchDate,
          bookingType,
          brandCode,
          businessName,
          city,
          contractNumber,
          countryCode,
          customerId,
          dealerCustomerId,
          effectiveDt,
          email,
          expirationDt,
          firstName,
          forcedAppointment,
          lastName,
          model,
          postalCode,
          primaryPhone,
          procedureCode,
          productCode,
          productTierCode,
          secondaryPhone,
          serial,
          serviceElevation,
          serviceNotice,
          serviceType,
          state,
          warrantyType,
        } = this.state;

        const searchDt = beginDt
          ? moment(beginDt).format('YYYY-MM-DD')
          : moment(beginSearchDate).format('YYYY-MM-DD');

        const response = await getAppointments(
          address,
          address2,
          bookingType,
          businessName,
          primaryPhone,
          city,
          countryCode,
          customerId,
          dealerCustomerId,
          email,
          firstName,
          lastName,
          secondaryPhone,
          state,
          postalCode,
          brandCode,
          model,
          productCode,
          productTierCode,
          serial,
          searchDt,
          procedureCode,
          serviceElevation,
          serviceNotice,
          serviceType,
          contractNumber,
          effectiveDt,
          expirationDt,
          warrantyType
        );

        this.setState({ loading: false });

        if (response.status === 200) {
          if (response.data.status !== 200) {
            const { data } = response;
            errorMessage += `<br/><b>Error getting appointments:</b><br/>${data.status} - ${data.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>';
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              } else {
                errorDetails = data?.data?.data?.details;
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              }
            }

            this.setState({ errorMessage, loading: false });
            window.location.href = '#errorFound';
          }

          appointments = response?.data.data;

          const forcedAppointmentList = appointments.filter(
            (appointment) =>
              appointment.appointmentInfo.svcrName?.toUpperCase() ===
              'NO SERVICERS FOUND'
          );

          let forcedAppointmentDetail = { ...forcedAppointment };
          if (forcedAppointmentList.length > 0) {
            forcedAppointmentDetail = {
              ...forcedAppointment,
              appointmentInfo: {
                ...forcedAppointment.appointmentInfo,
                appointmentBrand:
                  forcedAppointmentList[0].appointmentInfo.appointmentBrand,
                appointmentProduct:
                  forcedAppointmentList[0].appointmentInfo.appointmentProduct,
              },
            };
          }

          let appointmentList = [];
          if (appointments.length > 0) {
            appointmentList = appointments.filter(
              (appointment) =>
                moment(
                  appointment.appointmentInfo.timeSlotData.availableDate
                ).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') &&
                appointment.appointmentInfo.svcrName?.toUpperCase() !==
                'NO SERVICERS FOUND'
            );
          }
          if (appointmentList.length > 0) {
            if (
              appointments[0].appointmentInfo.svcrStatus?.toUpperCase() ===
              'ONPOINT SPECIFIC SERVICER'.toUpperCase()
            ) {
              appointments[0].appointmentInfo.timeSlotData.availableDate =
                moment().format('YYYY-MM-DD');
              appointments[0].appointmentInfo.timeSlotData.timePeriod = '8-17';
              selectedAppointment = appointments[0];
            }
          }
          this.setState({
            appointmentList,
            forcedAppointment: forcedAppointmentDetail,
            selectedAppointment,
            showAppointmentList: true,
          });
        } else {
          errorMessage += `<br/><b>Error with get appointments:</b><br/>${response.status} - ${response.message}`;
          this.setState({ errorMessage });
          window.location.href = '#errorFound';
        }
      }
    } catch (error) {
      let errorMessage = '';
      errorMessage +=
        '<br/><b>Error with get appointments:</b><br/>' + error.Message;
      this.setState({
        errorMessage,
      });
      window.location.href = '#errorFound';
    }
  };

  getDispatchMessageList = async () => {
    let errorMessage = '';
    try {
      const response = await getDispatchMessageList();
      const { data } = response;
      if (response.status === 200) {
        if (response.data.status !== 200) {
          errorMessage += `<b>Error while getting dispatch messages:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = '#errorFound';
          return null;
        } else {
          this.setState({ dispatchMessageList: response.data.data[0] });
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get dispatch messages:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting subcategories:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getSubcategories = async () => {
    let errorMessage = '';
    try {
      const response = await getSubcategories();
      const { data } = response;
      if (response.status === 200) {
        if (response.data.status !== 200) {
          errorMessage += `<b>Error while getting subcategories:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = '#errorFound';
          return null;
        } else {
          this.setState({ subcategories: response.data.data.subcategories });
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get subcategories:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting subcategories:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getCancelReasons = async () => {
    let errorMessage = '';
    try {
      const response = await getCancelReasons();
      const { data } = response;
      if (response.status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting cancel reasons:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = '#errorFound';
          return null;
        } else {
          this.setState({ cancelReasons: response.data.data });
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get cancel reasons:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error when getting cancel reasons:</b><br/>${error}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getManufacturers = async () => {
    let errorMessage = '';
    try {
      const response = await getManufacturers();
      const { data } = response;
      if (response.status === 200) {
        if (data.status !== 200) {
          const { data } = response;
          errorMessage += `<br/><b>Error getting manufacturers:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = '#errorFound';
          return [];
        } else {
          this.setState({ manufacturers: response.data.data });
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get manufacturers:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting manufacturers:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getServiceOrders = async (contractNumber) => {
    let errorMessage = '';
    try {
      const response = await getServiceOrders(contractNumber);
      const { data } = response;
      if (response.status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting service orders:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = '#errorFound';
          return null;
        } else {
          const serviceOrderList = response.data.data;
          this.setState({ serviceOrderList });
          return serviceOrderList;
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get service orders:</b><br/>${response.status} - ${response.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting service orders:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  handleInputChange(event) {
    event.preventDefault();
    this.resetValidation();

    const { target } = event;
    const { id, value } = target;

    if (id === 'contractType') {
      this.getManufacturers();
    }

    if (id !== 'subCategory' && id !== 'newSubcategory') {
      this.setState({
        [id]: value,
        showAppointmentList: false,
        selectedAppointment: null,
      });
    }

    if (id === 'subCategory') {
      const subCategory = this.state.subcategories.find(function (subcategory) {
        return subcategory.subCatDescription === value;
      });
      this.getSymptomCodeList(value);

      this.setState({
        subCategory: value,
        productCode: subCategory.subCatCode,
        productTierCode: subCategory.tierCode,
      });
    }

    if (id === 'newSubcategory') {
      const subCategory = this.state.subcategories.find(function (subcategory) {
        return subcategory.subCatDescription === value;
      });

      this.setState({
        newSubcategory: value,
        newProductCode: subCategory.subCatCode,
        newProductTierCode: subCategory.tierCode,
      });
    }

    if (id === 'brand') {
      const brandDetails = this.state.manufacturers.find(function (
        brandDetail
      ) {
        return brandDetail.brand.toUpperCase() === value;
      });
      this.setState({ brand: value, brandCode: brandDetails.brandCode });
    }
  }

  handleFailureDateSelect(date) {
    this.setState({ failureDt: moment(date).format('MM/DD/YYYY') });
  }

  handleAppointmentSelect = (appointment) => {
    this.setState({ selectedAppointment: appointment });
  };

  handleServiceOrderSelect = async (serviceOrder, resetFlg) => {
    this.resetValidation();
    this.setState({ errorMessage: '' });

    console.log('Service order: ', serviceOrder);

    const {
      appointmentNotes,
      address1,
      address2,
      city,
      claimFailureNote,
      claimReimbursementMethod,
      claimStatus,
      claimStorySymptom,
      country,
      email,
      firstName,
      lastName,
      mobileNumber,
      serviceOrderNumber,
      state,
      zipCode,
    } = serviceOrder;

    if (
      serviceOrderNumber ===
      this.state.selectedServiceOrder?.serviceOrderNumber &&
      resetFlg === 1
    ) {
      if (this.state.selectedContract != null) {
        const { customer } = this.state.selectedContract;
        const { address, email, firstName, lastName, phone } = customer;
        const { address1, address2, city, postalCode, state } = address;

        this.setState({
          address: address1,
          address2: address2,
          city,
          claimReimbursementMethod,
          email,
          existingAppointment: null,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          failureType: 'Standard',
          firstName,
          functionalityStatus: 'Functional',
          lastName,
          postalCode,
          primaryPhone: phone,
          selectedAppointment: null,
          selectedServiceOrder: null,
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceOrderClaimStatus: '',
          serviceOrderNumber: '',
          serviceType: 'Repair',
          showAppointmentList: false,
          state,
          symptomCode: '',
          warrantyType: 'Service Contract',
        });
      }
    } else {
      this.setState({
        address:
          address1?.length > 0 && address1 !== this.state.address
            ? address1
            : this.state.address,
        address2:
          address2?.length > 0 && address2 !== this.state.address2
            ? address2
            : this.state.address2,
        city:
          city?.length > 0 && city !== this.state.city ? city : this.state.city,
        claimReimbursementMethod,
        countryCode:
          country?.length > 0 && country !== this.state.countryCode
            ? country
            : this.state.countryCode,
        email:
          email?.length > 0 && email !== this.state.email
            ? email
            : this.state.email,
        failureNote: claimFailureNote,
        firstName:
          firstName?.length > 0 && firstName !== this.state.firstName
            ? firstName
            : this.state.firstName,
        lastName:
          lastName?.length > 0 && lastName !== this.state.lastName
            ? lastName
            : this.state.lastName,
        postalCode:
          zipCode?.length > 0 && zipCode !== this.state.postalCode
            ? zipCode
            : this.state.postalCode,
        primaryPhone:
          mobileNumber?.length > 0 && mobileNumber !== this.state.primaryPhone
            ? mobileNumber
            : this.state.primaryPhone,
        selectedServiceOrder: serviceOrder,
        servicerNotes: appointmentNotes,
        serviceOrderClaimStatus: claimStatus,
        serviceOrderNumber,
        serviceType:
          claimStatus?.toUpperCase() === 'Advanced Exchange–FNOL'.toUpperCase()
            ? 'Advanced Exchange'
            : this.state.serviceType,
        state:
          state?.length > 0 && state !== this.state.state
            ? state
            : this.state.state,
        symptomCode: claimStorySymptom,
      });

      await this.getAppointment(this.state.contractNumber, serviceOrderNumber);
    }
  };

  getAppointment = async (contractNumber, serviceOrderNumber) => {
    let errorMessage = this.state.errorMessage;
    if (contractNumber?.length > 0 && serviceOrderNumber?.length > 0) {
      try {
        const response = await getBookedAppointment(
          contractNumber,
          serviceOrderNumber
        );
        const { status, data } = response;
        if (status === 200) {
          if (data.status !== 200) {
            errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${data?.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>';
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              } else {
                errorDetails = data?.data?.data?.details;
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              }
            }

            this.setState({ errorMessage });
            window.location.href = '#errorFound';
          } else {
            const appointmentList = data.data;
            if (appointmentList.length > 0) {
              const appointmentDetails = appointmentList[0];
              const {
                failureDate,
                failureType,
                functionalityStatus,
                serviceRequestElevation,
                serviceRequestType,
                warrantyType,
              } = appointmentDetails;
              this.setState({
                existingAppointment: appointmentDetails,
                failureDt: moment.utc(failureDate).format('MM/DD/YYYY'),
                failureType,
                functionalityStatus,
                serviceElevation: serviceRequestElevation,
                serviceType: serviceRequestType,
                warrantyType,
              });
            } else {
              this.setState({
                existingAppointment: null,
                failureDt: moment(new Date()).format('MM/DD/YYYY'),
                failureType: 'Standard',
                functionalityStatus: 'Functional',
                serviceElevation: 'Normal',
                serviceType: 'Repair',
                warrantyType: 'Service Contract',
              });
            }
          }
        } else {
          errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${status}`;
          this.setState({ errorMessage });
          window.location.href = '#errorFound';
        }
      } catch (error) {
        errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${error.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    }
  };

  handleProductSelect = async (product) => {
    this.setState({ loading: true, errorMessage: '' });
    const { manufacturers, subcategories } = this.state;
    const {
      installDate,
      liabilityRemaining,
      liabilitySpent,
      mfg,
      modelNumber,
      productPurchaseDate,
      productSequenceNumber,
      purchasePrice,
      serial,
      serviceLocation,
      subCategory,
      totalLiability,
    } = product;

    let message = '';
    this.resetValidation();

    const brandDetails = manufacturers.find(function (brandDetail) {
      return brandDetail.brand.toUpperCase() === mfg.toUpperCase();
    });

    const subcategoryDetails = subcategories.find(function (subcategory) {
      return (
        subcategory.subCatDescription.toUpperCase() ===
        subCategory.toUpperCase()
      );
    });

    const brandMessageList = await this.getDispatchMessageListByBrand(mfg);
    if (brandMessageList.length > 0) {
      message = await this.getDipatchMessageByModel(
        brandMessageList,
        modelNumber
      );
    }

    this.setState({
      brand: mfg,
      brandCode: brandDetails.brandCode,
      dispatchMessage: message && message.length > 0 ? message : '',
      installDt: installDate,
      lolAvailable: liabilityRemaining,
      lolUsed: liabilitySpent,
      model: modelNumber,
      productCode: subcategoryDetails.subCatCode,
      productTierCode: subcategoryDetails.tierCode,
      purchaseDt: productPurchaseDate,
      subCategory,
      purchasePrice,
      selectedAppointment: null,
      selectedProduct: product,
      sequenceNumber: productSequenceNumber,
      serial,
      serviceLocation,
      showAppointmentList: false,
      showDispatchMessageModal: message.length > 0 ? true : false,
      totalLiability: totalLiability,
    });

    await this.getSymptomCodeList(subCategory);
    this.setState({ loading: false });
  };

  //This uses match to compare values. Some values were showing as !== even though they looked the same
  getDispatchMessageListByBrand = async (brandCode) => {
    const filteredList = this.state.dispatchMessageList.filter(function (
      message
    ) {
      return message.brandCode?.toUpperCase().match(brandCode.toUpperCase());
    });

    return filteredList;
  };

  //This uses match to compare values. Some values were showing as !== even though they looked the same
  getDipatchMessageByModel = async (messageList, model) => {
    const message = messageList.find((msg) => {
      return msg.model
        .toUpperCase()
        .normalize()
        .match(model.toUpperCase().normalize());
    });

    return message?.message ? message.message : '';
  };

  handleContractSelect = async (contractNumber) => {
    this.setState({ loading: true, errorMessage: '' });
    this.resetValidation();

    try {
      const selectedContract = await this.getContractDetails(contractNumber);

      this.setState({ loading: false });

      if (selectedContract != null && selectedContract !== undefined) {
        const {
          components,
          coverage,
          customer,
          dealerName,
          dealerStoreNumber,
          effectiveDate,
          expirationDate,
          product,
          referenceNumber,
          status,
        } = selectedContract;
        const { address, email, firstName, lastName, phone } = customer;
        const { address1, address2, city, countryCode, postalCode, state } =
          address;
        const { productType, terms } = coverage;
        const { deductible } = terms[0];
        const { type, amount } = deductible;
        const { code, description } = status;

        this.setState({
          address: address1,
          address2: address2,
          brand: '',
          city,
          contractNumber: contractNumber,
          contractStatus: description,
          contractStatusCode: code,
          countryCode,
          coverageProductTypeCodeStr: productType.code,
          dealerName,
          dealerCustomerId: dealerStoreNumber,
          deductible: amount,
          deductibleTerm: type,
          effectiveDt: moment(effectiveDate).format('MM/DD/YYYY'),
          email,
          expirationDt: moment(expirationDate).format('MM/DD/YYYY'),
          externalContractNumber: referenceNumber,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          symptomCode: '',
          filteredProductList: [],
          firstName,
          functionalityStatus: 'Functional',
          isEffective: moment(effectiveDate).isAfter(
            moment().format('MM/DD/YYYY')
          )
            ? false
            : true,
          lastName,
          lolAvailable: '',
          lolUsed: '',
          lossCodeList: components.losscodes,
          model: '',
          postalCode,
          primaryPhone: phone,
          productCode: '',
          productTierCode: '',
          purchasePrice: '',
          selectedAppointment: null,
          selectedContract,
          selectedLossCode: null,
          selectedProduct: null,
          sequenceNumber: '',
          serial: '',
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceType: 'Repair',
          state,
          totalLiability: '',
          unfilteredProductList: product,
          warrantyType: 'Service Contract',
        });

        await this.getServiceOrders(contractNumber);
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage;
      errorMessage += `<br/><b>Error while selecting contract:</b><br/>${error.message}`;
      this.setState({
        errorMessage,
      });
      window.location.href = '#errorFound';
    }
  };

  handleNewContractSelect = async (contract) => {
    this.setState({ loading: true, errorMessage: '' });
    this.resetValidation();

    try {
      const selectedContract = await this.getContractDetails(
        contract.contractNumber
      );

      this.setState({ loading: false });

      if (selectedContract != null && selectedContract !== undefined) {
        const {
          components,
          contractNumber,
          coverage,
          customer,
          dealerName,
          dealerStoreNumber,
          effectiveDate,
          expirationDate,
          product,
          referenceNumber,
          status,
        } = selectedContract;
        const { address, email, firstName, lastName, phone } = customer;
        const { address1, address2, city, postalCode, state } = address;
        const { productType, terms } = coverage;
        const { deductible } = terms[0];
        const { type, amount } = deductible;
        const { code, description } = status;

        this.setState({
          address: address1,
          address2: address2,
          brand: '',
          city,
          contractNumber: contractNumber,
          contractStatus: description,
          contractStatusCode: code,
          coverageProductTypeCodeStr: productType.code,
          dealerName,
          dealerCustomerId: dealerStoreNumber,
          deductible: amount,
          deductibleTerm: type,
          effectiveDt: moment(effectiveDate).format('MM/DD/YYYY'),
          email,
          expirationDt: moment(expirationDate).format('MM/DD/YYYY'),
          externalContractNumber: referenceNumber,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          symptomCode: '',
          filteredProductList: [],
          firstName,
          functionalityStatus: 'Functional',
          isEffective: moment(effectiveDate).isAfter(
            moment().format('MM/DD/YYYY')
          )
            ? false
            : true,
          lastName,
          lolAvailable: '',
          lolUsed: '',
          lossCodeList: components.losscodes,
          model: '',
          newContract: selectedContract,
          postalCode,
          primaryPhone: phone,
          productCode: '',
          productTierCode: '',
          purchasePrice: '',
          selectedAppointment: null,
          selectedContract,
          selectedLossCode: null,
          selectedProduct: null,
          sequenceNumber: '',
          serial: '',
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceType: 'Repair',
          state,
          totalLiability: '',
          unfilteredProductList: product,
          warrantyType: 'Service Contract',
        });

        await this.getServiceOrders(contractNumber);
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage;
      errorMessage += `<br/><b>Error while getting new contract details:</b><br/>${error.message}`;
      this.setState({
        errorMessage,
      });
      window.location.href = '#errorFound';
    }
  };

  handleNewContractDetails = async (contractNumber) => {
    this.setState({ loading: true, errorMessage: '' });
    this.resetValidation();

    try {
      const selectedContract = await this.getContractDetails(contractNumber);

      this.setState({ loading: false });

      if (selectedContract != null && selectedContract !== undefined) {
        const {
          components,
          coverage,
          customer,
          dealerName,
          dealerStoreNumber,
          effectiveDate,
          expirationDate,
          product,
          referenceNumber,
          status,
        } = selectedContract;
        const { address, email, firstName, lastName, phone } = customer;
        const { address1, address2, city, postalCode, state } = address;
        const { productType, terms } = coverage;
        const { deductible } = terms[0];
        const { type, amount } = deductible;
        const { code, description } = status;

        this.setState({
          address: address1,
          address2: address2,
          brand: '',
          city,
          contractNumber: contractNumber,
          contractStatus: description,
          contractStatusCode: code,
          coverageProductTypeCodeStr: productType.code,
          dealerName,
          dealerCustomerId: dealerStoreNumber,
          deductible: amount,
          deductibleTerm: type,
          effectiveDt: moment(effectiveDate).format('MM/DD/YYYY'),
          email,
          expirationDt: moment(expirationDate).format('MM/DD/YYYY'),
          externalContractNumber: referenceNumber,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          symptomCode: '',
          filteredProductList: [],
          firstName,
          functionalityStatus: 'Functional',
          isEffective: moment(effectiveDate).isAfter(
            moment().format('MM/DD/YYYY')
          )
            ? false
            : true,
          lastName,
          lolAvailable: '',
          lolUsed: '',
          lossCodeList: components.losscodes,
          model: '',
          newContract: selectedContract,
          postalCode,
          primaryPhone: phone,
          productCode: '',
          productTierCode: '',
          purchasePrice: '',
          selectedAppointment: null,
          selectedContract,
          selectedLossCode: null,
          selectedProduct: null,
          sequenceNumber: '',
          serial: '',
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceType: 'Repair',
          state,
          totalLiability: '',
          unfilteredProductList: product,
          warrantyType: 'Service Contract',
        });

        await this.getServiceOrders(contractNumber);
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage;
      errorMessage += `<br/><b>Error while getting new contract details:</b><br/>${error.message}`;
      this.setState({
        errorMessage,
      });
      window.location.href = '#errorFound';
    }
  };

  handleLossCodeSelect = async (lossCode) => {
    this.resetValidation();
    this.setState({ loading: true, errorMessage: '' });

    if (lossCode != null && lossCode !== '') {
      const { subcategories, unfilteredProductList } = this.state;
      const subCategory = subcategories.find(function (subcategory) {
        return subcategory.subCatCode === lossCode.code;
      });
      const productCode = subCategory?.subCatCode;
      const subCategoryDescription =
        subCategory?.subCatDescription?.toUpperCase();
      const filteredProductList = unfilteredProductList.filter(function (
        product
      ) {
        return (
          product.subCategory.toUpperCase() ===
          subCategory?.subCatDescription?.toUpperCase()
        );
      });

      this.setState({
        brand: '',
        filteredProductList: filteredProductList,
        model: '',
        productCode,
        productTierCode: subCategory?.tierCode,
        newProductCode: productCode,
        newProductTierCode: subCategory?.tierCode,
        subCategory: subCategoryDescription,
        newSubcategory: subCategoryDescription,
        newTierCode: subCategory?.tierCode,
        purchasePrice: '',
        lolUsed: '',
        lolAvailable: '',
        selectedAppointment: null,
        selectedLossCode: lossCode,
        selectedProduct: null,
        sequenceNumber: '',
        serial: '',
        totalLiability: '',
      });

      this.resetValidation();
      await this.getSymptomCodeList(subCategory?.subCatDescription);
    }
    this.setState({ loading: false });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
  };

  validateCancel = () => {
    const { cancelReasonCode } = this.state;

    this.setState({
      errorMessage: '',
      isCancelReasonValidated: true,
      isCancelReasonValid: cancelReasonCode?.trim().length === 0 ? false : true,
    });

    return cancelReasonCode.length > 0;
  };

  validatePage = () => {
    const {
      brand,
      model,
      serial,
      subCategory,
      postalCode,
      selectedProduct,
      symptomCode,
      failureNote,
    } = this.state;

    let errorMessage = '';
    let hasDifference =
      (selectedProduct.mfg.toUpperCase() !== brand.toUpperCase() &&
        brand.length > 0) ||
      (selectedProduct.modelNumber.toUpperCase() !== model.toUpperCase() &&
        model.length > 0) ||
      (selectedProduct.serial.toUpperCase() !== serial.toUpperCase() &&
        serial.length > 0) ||
      (selectedProduct.subCategory !== subCategory && subCategory.length > 0);

    if (hasDifference) {
      errorMessage = 'Please update the product before continuing.';
      window.location.href = '#errorFound';
    }

    this.setState({
      errorMessage,
      isBrandValid:
        brand.length === 0 || brand.toUpperCase() === 'UNKNOWN' ? false : true,
      isBrandValidated: true,
      isModelValid:
        model.length === 0 || model.toUpperCase() === 'UNKNOWN' ? false : true,
      isModelValidated: true,
      isSerialValid:
        serial.length === 0 || serial.toUpperCase() === 'UNKNOWN'
          ? false
          : true,
      isSerialValidated: true,
      isSubcategoryValid:
        subCategory.length === 0 || subCategory.toUpperCase() === 'UNKNOWN'
          ? false
          : true,
      isSubcategoryValidated: true,
      isPostalCodeValidated: true,
      isPostalCodeValid: postalCode.length < 5 ? false : true,
      isSymptomCodeValidated: true,
      isSymptomCodeValid: symptomCode.length === 0 ? false : true,
      isFailureNoteValidated: true,
      isFailureNoteValid: failureNote.length === 0 ? false : true,
    });

    if (
      brand.length === 0 ||
      brand.toUpperCase() === 'UNKNOWN' ||
      model.length === 0 ||
      model.toUpperCase() === 'UNKNOWN' ||
      serial.length === 0 ||
      serial.toUpperCase() === 'UNKNOWN' ||
      postalCode.length === 0 ||
      subCategory.toUpperCase() === 'UNKNOWN' ||
      hasDifference
    ) {
      window.location.href = '#ProductError';
    }

    return (
      brand.length > 0 &&
      brand.toUpperCase() !== 'UNKNOWN' &&
      model.length > 0 &&
      model.toUpperCase() !== 'UNKNOWN' &&
      serial.length > 0 &&
      serial.toUpperCase() !== 'UNKNOWN' &&
      postalCode.length > 0 &&
      failureNote.length > 0 &&
      subCategory.toUpperCase() !== 'UNKNOWN' &&
      symptomCode.length > 0 &&
      !hasDifference
    );
  };

  resetValidation = () => {
    this.setState({
      isBrandValid: null,
      isBrandValidated: false,
      isModelValid: null,
      isModelValidated: false,
      isSerialValid: null,
      isSerialValidated: false,
      isSubcategoryValid: null,
      isSubcategoryValidated: false,
      isPostalCodeValidated: false,
      isPostalCodeValid: null,
      isSymptomCodeValid: null,
      isSymptomCodeValidated: false,
      isFailureNoteValid: null,
      isFailureNoteValidated: false,
      showAppointmentList: false,
      selectedAppointment: null,
    });
  };

  getContractDetails = async (contractNumber) => {
    try {
      let errorMessage = '';

      const response = await getContractDetails(contractNumber);
      const { status, data, statusText } = response;

      if (status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting contract details:</b><br/>${data?.status} - ${data?.message}`;
          errorMessage += `<br/>Attempted to search for contract ${contractNumber}.`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            } else {
              errorDetails = data?.data?.data?.details;
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = '#errorFound';
          return null;
        } else {
          return data.data.contract;
        }
      } else {
        errorMessage = this.state.errorMessage;
        errorMessage += `<br/><b>Error while getting contract details:</b><br/> ${status} - ${statusText}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
        return null;
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting contract details:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getSymptomCodeList = async (subcategory) => {
    try {
      const { subcategories } = this.state;

      let errorMessage = this.state.errorMessage;
      const category = subcategories.find(function (category) {
        return (
          category.subCatDescription?.toUpperCase() ===
          subcategory?.toUpperCase()
        );
      });

      if (category != null) {
        if (category?.tierCode != null && category?.tierCode !== '') {
          const { tierCode } = category;
          const response = await getFailureTypes(tierCode);
          const { status, data } = response;

          if (status === 200) {
            if (data.status !== 200) {
              errorMessage += `<br/><b>Error while getting failure types:</b><br/>${data?.data?.code} - ${data?.data?.message}`;
              errorMessage += `<br/>Attempted to search for tier code ${tierCode}.`;

              let errorDetails = data?.data?.details;

              if (errorDetails?.length > 0) {
                errorMessage += '<ul>';
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
                });
                errorMessage += '</ul>';
              } else {
                errorDetails = data?.data;
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>';
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[
                        key
                      ].toString()}</li>`;
                    });
                    errorMessage += '</ul>';
                  });
                } else {
                  errorDetails = data?.data?.data?.details;
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>';
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[
                        key
                      ].toString()}</li>`;
                    });
                    errorMessage += '</ul>';
                  });
                }
              }

              this.setState({ errorMessage });
              window.location.href = '#errorFound';
            } else {
              const list = data.data.failures;
              this.setState({ symptomCodeList: list.length > 0 ? list : [] });
            }
          } else {
            errorMessage += `<br/><b>Error while getting failure types:</b><br/>${status}`;
            this.setState({ errorMessage });
            window.location.href = '#errorFound';
          }
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting failure types:</b><br/>${error.message}`,
      });
      window.location.href = '#errorFound';
    }
  };

  getNextWeek = async () => {
    let beginDt = new Date(this.state.beginSearchDate);
    beginDt.setDate(beginDt.getDate() + 7);
    this.setState({
      beginSearchDate: moment(beginDt).format('MM/DD/YYYY'),
      selectedAppointment: null,
    });
    this.getServiceAppointments(beginDt);
  };

  getLastWeek() {
    let beginDt = new Date(this.state.beginSearchDate);
    beginDt.setDate(beginDt.getDate() - 7);
    this.setState({
      beginSearchDate: moment(beginDt).format('MM/DD/YYYY'),
      selectedAppointment: null,
    });
    this.getServiceAppointments(beginDt);
  }

  getPreviousButton() {
    if (
      moment(this.state.beginSearchDate).isSameOrBefore(
        moment().format('MM/DD/YYYY')
      )
    ) {
      return null;
    } else {
      return (
        <Button
          className="ms-1 me-1 RoundedButton"
          disabled={this.state.loading}
          onClick={() => {
            this.getLastWeek();
          }}
          size="sm"
          variant="primary"
        >
          Appointments Prior Week
        </Button>
      );
    }
  }

  getNextButton() {
    if (
      moment(this.state.beginSearchDate).isSameOrAfter(
        moment().add(56, 'days').format('MM/DD/YYYY')
      )
    ) {
      return null;
    } else {
      return (
        <Button
          className="ms-1 me-1 RoundedButton"
          disabled={this.state.loading}
          onClick={() => {
            this.getNextWeek();
          }}
          size="sm"
          variant="primary"
        >
          Appointments Next Week
        </Button>
      );
    }
  }

  getDisplay() {
    const {
      address,
      address2,
      appointmentList,
      appointmentWorking,
      beginDt,
      beginSearchDate,
      bookingType,
      brand,
      cancelReasons,
      cancelReasonCode,
      city,
      claimReimbursementMethod,
      condition,
      contractList,
      contractNumber,
      contractStatus,
      contractStatusCode,
      contractType,
      coverageProductTypeCodeStr,
      dealerName,
      deductible,
      deductibleTerm,
      dispatchMessage,
      effectiveDt,
      email,
      endDt,
      errorMessage,
      existingAppointment,
      expirationDt,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      filteredProductList,
      firstName,
      functionalityStatus,
      hasConcessionFlg,
      isBrandValid,
      isBrandValidated,
      isCancelReasonValid,
      isCancelReasonValidated,
      isEffective,
      isFailureNoteValid,
      isFailureNoteValidated,
      isModelValid,
      isModelValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isSubcategoryValid,
      isSubcategoryValidated,
      isSerialValid,
      isSerialValidated,
      isSymptomCodeValid,
      isSymptomCodeValidated,
      kustomerEmail,
      kustomerPhone,
      lastName,
      loading,
      lolAvailable,
      lolUsed,
      lossCodeList,
      manufacturers,
      model,
      newContract,
      postalCode,
      primaryPhone,
      procedureCode,
      purchasePrice,
      secondaryPhone,
      selectedAppointment,
      selectedContract,
      selectedLossCode,
      selectedProduct,
      selectedServiceOrder,
      serial,
      serviceElevation,
      servicerNotes,
      serviceNotice,
      serviceOrderClaimStatus,
      serviceOrderList,
      serviceOrderNumber,
      serviceType,
      showAppointmentList,
      showBookSuccess,
      showCancelSuccess,
      showDispatchMessageModal,
      showFNOLSuccess,
      state,
      subCategory,
      subcategories,
      symptomCode,
      symptomCodeList,
      totalLiability,
      unfilteredProductList,
      warrantyType,
    } = this.state;

    return (
      <Fragment>
        <Navbar bg="white" fixed="top">
          <Container>
            <Navbar.Brand>
              <Image src={logo} fluid />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <Button
                  className="RoundedButton"
                  onClick={() => window.location.reload()}
                >
                  <FontAwesomeIcon icon={faPlus} /> Start a New Service
                </Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Form noValidate>
          <Row className="mt-5 ps-3 pe-3">
            <Col md={12}>
              <Modal show={showDispatchMessageModal} keyboard={false}>
                <Modal.Header>
                  <Modal.Title>Important Dispatch Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Alert variant="warning">
                        <Alert.Heading>
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp;Notice
                        </Alert.Heading>
                        <p>{dispatchMessage}</p>
                      </Alert>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({ showDispatchMessageModal: false })
                    }
                  >
                    I Understand
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
          <Row className="mt-5 ps-3 pe-3">
            <Col md={12}>
              <ContractModal
                handleNewContractDetails={this.handleNewContractDetails}
                kustomerEmail={kustomerEmail}
                kustomerPhone={kustomerPhone}
                key={contractNumber}
              ></ContractModal>
            </Col>
          </Row>
          <Row className="mt-5 ps-3 pe-3">
            <Col md={12}>
              <ContractList
                contractList={contractList}
                handleContractSelect={this.handleContractSelect}
                handleNewContractSelect={this.handleNewContractSelect}
                newContract={newContract}
                selectedContract={selectedContract}
                key={newContract}
              />
            </Col>
          </Row>
          <Row className="mt-5 ps-3 pe-3">
            <Col md={12}>
              {selectedContract ? (
                <Contract
                  contractNumber={contractNumber}
                  contractStatus={contractStatus}
                  contractStatusCode={contractStatusCode}
                  dealerName={dealerName}
                  deductible={deductible}
                  deductibleTerm={deductibleTerm}
                  effectiveDt={effectiveDt}
                  expirationDt={expirationDt}
                  isEffective={isEffective}
                  key={contractNumber}
                  lolUsed={lolUsed}
                  lolAvailable={lolAvailable}
                  selectedContract={selectedContract}
                  selectedProduct={selectedProduct}
                  totalLiability={totalLiability}
                ></Contract>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-5 ps-3 pe-3">
            {selectedContract &&
              isEffective &&
              coverageProductTypeCodeStr.toUpperCase() === 'RSC' ? (
              <Col md={6}>
                <LossCodeList
                  handleLossCodeSelect={this.handleLossCodeSelect}
                  loading={loading}
                  lossCodeList={lossCodeList}
                  selectedLossCode={selectedLossCode}
                />
              </Col>
            ) : null}

            {(selectedContract &&
              isEffective &&
              selectedLossCode &&
              coverageProductTypeCodeStr.toUpperCase() === 'RSC') ||
              (selectedContract &&
                isEffective &&
                coverageProductTypeCodeStr.toUpperCase() !== 'RSC') ? (
              <Col md={6}>
                <ProductList
                  condition={condition}
                  handleAddProduct={this.handleAddProduct}
                  handleInputChange={this.handleInputChange.bind(this)}
                  handleProductSelect={this.handleProductSelect}
                  isExpired={contractStatusCode !== 'A' ? true : false}
                  loading={loading}
                  manufacturers={manufacturers}
                  subCategory={subCategory}
                  productList={
                    coverageProductTypeCodeStr.toUpperCase() === 'RSC'
                      ? filteredProductList
                      : unfilteredProductList
                  }
                  selectedLossCode={
                    selectedLossCode?.code
                      ? selectedLossCode?.code.toUpperCase()
                      : null
                  }
                  selectedProduct={selectedProduct}
                  subcategories={subcategories}
                />
              </Col>
            ) : null}
          </Row>
          <Row className="mt-5 ps-3 pe-3">
            {selectedProduct ? (
              <Col md={12}>
                <span id="ProductError"></span>
                <Product
                  beginDt={beginDt}
                  brand={brand.toUpperCase()}
                  endDt={endDt}
                  handleInputChange={this.handleInputChange.bind(this)}
                  handleUpdateProduct={this.handleUpdateProduct}
                  handleResetProduct={this.handleResetProduct}
                  isBrandValidated={isBrandValidated}
                  isBrandValid={isBrandValid}
                  isModelValidated={isModelValidated}
                  isModelValid={isModelValid}
                  isSubcategoryValidated={isSubcategoryValidated}
                  isSubcategoryValid={isSubcategoryValid}
                  isSerialValidated={isSerialValidated}
                  isSerialValid={isSerialValid}
                  loading={loading}
                  manufacturers={manufacturers}
                  model={model}
                  subCategory={subCategory.toUpperCase()}
                  purchasePrice={purchasePrice}
                  selectedLossCode={
                    selectedLossCode?.code
                      ? selectedLossCode?.code.toUpperCase()
                      : null
                  }
                  selectedProduct={selectedProduct}
                  serial={serial}
                  subcategories={subcategories}
                />
              </Col>
            ) : null}
          </Row>
          {selectedProduct ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <ServiceOrderList
                  contractNumber={contractNumber}
                  deductible={deductible}
                  deductibleTerm={deductibleTerm}
                  externalContractNumber={externalContractNumber}
                  serviceOrderList={serviceOrderList}
                  selectedServiceOrder={selectedServiceOrder}
                  handleServiceOrderSelect={this.handleServiceOrderSelect.bind(
                    this
                  )}
                />
              </Col>
            </Row>
          ) : null}
          {selectedProduct ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col md={4} id="existingAppointment">
                <ExistingAppointment
                  existingAppointment={existingAppointment}
                  appointmentWorking={appointmentWorking}
                  cancelReasons={cancelReasons}
                  cancelReasonCode={cancelReasonCode}
                  contractNumber={contractNumber}
                  handleCancelAppointment={this.handleCancelAppointment.bind(
                    this
                  )}
                  handleInputChange={this.handleInputChange.bind(this)}
                  getAppointment={this.getAppointment.bind(this)}
                  isCancelReasonValid={isCancelReasonValid}
                  isCancelReasonValidated={isCancelReasonValidated}
                  serviceOrderNumber={serviceOrderNumber}
                ></ExistingAppointment>
              </Col>
            </Row>
          ) : null}
          {showCancelSuccess ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Alert variant="success">
                  <h1>Success!</h1>
                  <p>Appointment was cancelled successfully.</p>
                </Alert>
              </Col>
            </Row>
          ) : null}
          {showBookSuccess ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Alert variant="success">
                  <h1>Success!</h1>
                  <p>Appointment was booked successfully.</p>
                </Alert>
              </Col>
            </Row>
          ) : null}
          {showFNOLSuccess ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Alert variant="success">
                  <h1>Success!</h1>
                  <p>{`FNOL claim ${serviceOrderNumber} created for contract ${contractNumber}.`}</p>
                </Alert>
              </Col>
            </Row>
          ) : null}
          <Row className="mt-5 ps-3 pe-3">
            {selectedProduct ? (
              <Col md={6} className="pe-5">
                <Service
                  bookingType={bookingType}
                  contractType={contractType}
                  failureNote={failureNote}
                  failureDt={failureDt}
                  failureType={failureType}
                  symptomCode={symptomCode}
                  symptomCodeList={symptomCodeList}
                  functionalityStatus={functionalityStatus}
                  handleFailureDateSelect={this.handleFailureDateSelect.bind(
                    this
                  )}
                  handleInputChange={this.handleInputChange.bind(this)}
                  isFailureNoteValid={isFailureNoteValid}
                  isFailureNoteValidated={isFailureNoteValidated}
                  isSymptomCodeValid={isSymptomCodeValid}
                  isSymptomCodeValidated={isSymptomCodeValidated}
                  serviceElevation={serviceElevation}
                  servicerNotes={servicerNotes}
                  serviceNotice={serviceNotice}
                  serviceOrderNumber={serviceOrderNumber}
                  serviceType={serviceType}
                  warrantyType={warrantyType}
                />
              </Col>
            ) : null}
            {selectedContract && selectedProduct ? (
              <Col md={6} className="ps-5">
                <Customer
                  address={address}
                  address2={address2}
                  city={city}
                  email={email}
                  firstName={firstName}
                  handleInputChange={this.handleInputChange.bind(this)}
                  isPostalCodeValid={isPostalCodeValid}
                  isPostalCodeValidated={isPostalCodeValidated}
                  lastName={lastName}
                  postalCode={postalCode}
                  primaryPhone={primaryPhone}
                  secondaryPhone={secondaryPhone}
                  state={state}
                ></Customer>
              </Col>
            ) : null}
          </Row>
          {errorMessage.length > 0 ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Alert id="errorFound" variant="danger">
                  {parse(errorMessage)}
                </Alert>
              </Col>
            </Row>
          ) : null}
          {showAppointmentList &&
            appointmentList.length > 0 &&
            appointmentList[0].appointmentInfo.svcrStatus?.toUpperCase() ===
            'ONPOINT SPECIFIC SERVICER'.toUpperCase() &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) ? (
            <Fragment>
              <Row className="mt-3 ps-3 pe-3 mb-3">
                <Col md={3}>
                  <Card>
                    <Card.Header className="bg-purple text-center">
                      <h5 className="text-white">Depot</h5>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        Book to {appointmentList[0].appointmentInfo?.svcrName}
                      </Card.Text>
                      <Col className="text-center">
                        <Button
                          className="ms-1 mb-1 RoundedButton"
                          disabled={loading}
                          onClick={() =>
                            this.handleDepotBookAppointment(selectedAppointment)
                          }
                          size="sm"
                          variant="primary"
                        >
                          Book
                        </Button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Fragment>
          ) : null}
          {showAppointmentList &&
            appointmentList.length === 0 &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) ? (
            <Fragment>
              <Row className="mt-3 ps-3 pe-3 mb-3">
                <Col md={3}>
                  <Card>
                    <Card.Header className="bg-purple text-center text-white">
                      <h5>Dispatch Alert!</h5>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        Currently, no appointments exist for this particular
                        dispatch. Please Force Book Directly to ONPOINT for
                        further research!
                      </Card.Text>
                      <Col className="text-center">
                        <Button
                          className="ms-1 mb-1 RoundedButton"
                          disabled={loading}
                          onClick={() => this.handleForceBookAppointment()}
                          size="sm"
                          variant="primary"
                        >
                          Force Book to OnPoint
                        </Button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Fragment>
          ) : null}
          {showAppointmentList &&
            appointmentList.length > 0 &&
            appointmentList[0].appointmentInfo.svcrStatus?.toUpperCase() !==
            'ONPOINT SPECIFIC SERVICER'.toUpperCase() &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) ? (
            <Fragment>
              <AppointmentList
                appointmentList={appointmentList}
                appointmentWorking={appointmentWorking}
                beginSearchDate={beginSearchDate}
                handleAppointmentSelect={this.handleAppointmentSelect}
                key={beginSearchDate}
                procedureCode={procedureCode}
                selectedAppointment={selectedAppointment}
              />
              <Row className="mt-3 ps-3 pe-3">
                <Col>
                  {!appointmentWorking && this.getPreviousButton()}
                  {!appointmentWorking && this.getNextButton()}
                </Col>
                {showAppointmentList &&
                  !selectedAppointment &&
                  !existingAppointment &&
                  !appointmentWorking ? (
                  <Col>
                    <Button
                      className="ms-1 mb-1 float-end RoundedButton"
                      disabled={loading}
                      onClick={() => this.handleForceBookAppointment()}
                      size="sm"
                      variant="primary"
                    >
                      Force Book to OnPoint
                    </Button>
                  </Col>
                ) : null}
                {showAppointmentList &&
                  selectedAppointment &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !==
                      'CANCELLED')) &&
                  !appointmentWorking ? (
                  <Col>
                    <Button
                      className="ms-1 mb-1 float-end RoundedButton"
                      disabled={loading}
                      onClick={() => this.handleBookAppointment()}
                      size="sm"
                      variant="primary"
                    >
                      Book Appointment
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Fragment>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() !== 'Advanced Exchange'.toUpperCase() &&
            serviceType.toUpperCase() !== 'Standard Exchange'.toUpperCase() &&
            serviceType.toUpperCase() !==
            'Outbound Shipment Only'.toUpperCase() &&
            (deductible === 0 ||
              (deductible > 0 &&
                claimReimbursementMethod.toUpperCase() ===
                'Concession'.toUpperCase()) ||
              (deductible > 0 &&
                serviceNotice.toUpperCase() === 'Repeat Request'.toUpperCase()) ||
              serviceNotice.toUpperCase() === 'Armadillo'.toUpperCase() ||
              (deductible > 0 &&
                serviceNotice.toUpperCase() !== 'Repeat Request'.toUpperCase() &&
                serviceNotice.toUpperCase() !== 'Armadillo'.toUpperCase() &&
                selectedServiceOrder &&
                serviceOrderClaimStatus.toUpperCase() !==
                'Deductible-FNOL'.toUpperCase())) &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Button
                  className="ms-1 mb-1 RoundedButton"
                  disabled={loading}
                  onClick={() => this.getServiceAppointments('')}
                  size="sm"
                  variant="primary"
                >
                  Find Appointment
                </Button>
              </Col>
            </Row>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() !== 'Advanced Exchange'.toUpperCase() &&
            serviceType.toUpperCase() !== 'Standard Exchange'.toUpperCase() &&
            serviceType.toUpperCase() !==
            'Outbound Shipment Only'.toUpperCase() &&
            deductible > 0 &&
            claimReimbursementMethod.toUpperCase() !==
            'Concession'.toUpperCase() &&
            serviceNotice.toUpperCase() !== 'Repeat Request'.toUpperCase() &&
            serviceNotice.toUpperCase() !== 'Armadillo'.toUpperCase() &&
            selectedServiceOrder &&
            serviceOrderClaimStatus.toUpperCase() ===
            'Deductible-FNOL'.toUpperCase() &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col md={5}>
                <Alert variant="info">
                  <strong>Deductible not paid:</strong> The deductible is still
                  pending collection.
                </Alert>
              </Col>
            </Row>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() === 'Advanced Exchange'.toUpperCase() &&
            selectedServiceOrder &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col md={5}>
                <Alert variant="info">
                  <strong>Advanced Exchange:</strong> No appointment needs
                  scheduled.
                </Alert>
              </Col>
            </Row>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() === 'Advanced Exchange'.toUpperCase() &&
            !selectedServiceOrder &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col xs="2">
                <Button
                  className="ms-1 mb-1 RoundedButton"
                  disabled={loading}
                  onClick={() => this.handleCreateFNOL()}
                  size="sm"
                  variant="primary"
                >
                  Begin Exchange
                </Button>
              </Col>
              <Col xs="1">
                <Form.Check
                  type="checkbox"
                  onChange={() => {
                    this.setState({ hasConcessionFlg: !hasConcessionFlg });
                  }}
                  label="Concession"
                  checked={hasConcessionFlg}
                />
              </Col>
            </Row>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() === 'Standard Exchange'.toUpperCase() &&
            !selectedServiceOrder &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Button
                  className="ms-1 mb-1 RoundedButton"
                  disabled={loading}
                  onClick={() => this.handleCreateFNOL()}
                  size="sm"
                  variant="primary"
                >
                  Initiate Standard Exchange
                </Button>
              </Col>
            </Row>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() ===
            'Outbound Shipment Only'.toUpperCase() &&
            !selectedServiceOrder &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col>
                <Button
                  className="ms-1 mb-1 RoundedButton"
                  disabled={loading}
                  onClick={() => this.handleCreateFNOL()}
                  size="sm"
                  variant="primary"
                >
                  Initiate Outbound Shipment Only
                </Button>
              </Col>
            </Row>
          ) : null}
          {!showAppointmentList &&
            serviceType.toUpperCase() !== 'Advanced Exchange'.toUpperCase() &&
            serviceType.toUpperCase() !== 'Standard Exchange'.toUpperCase() &&
            serviceType.toUpperCase() !==
            'Outbound Shipment Only'.toUpperCase() &&
            deductible > 0 &&
            serviceNotice.toUpperCase() !== 'Repeat Request'.toUpperCase() &&
            serviceNotice.toUpperCase() !== 'Armadillo'.toUpperCase() &&
            !selectedServiceOrder &&
            (!existingAppointment ||
              (existingAppointment?.forcedBookingFlag === 'NO' &&
                existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
            selectedProduct &&
            !appointmentWorking ? (
            <Row className="mt-3 ps-3 pe-3">
              <Col xs="2">
                <Button
                  className="ms-1 mb-1 RoundedButton"
                  disabled={loading}
                  onClick={() => this.handleCreateFNOL()}
                  size="sm"
                  variant="primary"
                >
                  Collect Deductible
                </Button>
              </Col>
              <Col xs="1">
                <Form.Check
                  type="checkbox"
                  onChange={() => {
                    this.setState({ hasConcessionFlg: !hasConcessionFlg });
                  }}
                  label="Concession"
                  checked={hasConcessionFlg}
                />
              </Col>
            </Row>
          ) : null}
        </Form>
        <Row>
          <Col className="text-center">
            <small>V2.08.11.22</small>
          </Col>
        </Row>
      </Fragment>
    );
  }

  getWidgetDisplay() {
    return (
      <Fragment>
        <Navbar bg="white" fixed="top">
          <Container>
            <Navbar.Brand>
              <Image src={logo} fluid />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <Button
                  className="RoundedButton"
                  onClick={() => window.location.reload()}
                >
                  <FontAwesomeIcon icon={faPlus} /> Start a New Service
                </Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Row className="mt-3">
          <Col md={6}>
            <Alert variant="warning">
              Service can only be scheduled from a customer conversation.
            </Alert>
          </Col>
        </Row>
      </Fragment>
    );
  }

  showPage() {
    const { contextType } = this.state;

    return (
      <Container fluid className="AppSetting">
        {contextType !== 'widget' ? this.getDisplay() : null}
        {contextType === 'widget' ? this.getWidgetDisplay() : null}
      </Container>
    );
  }

  showNoAccess() {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <Alert variant="danger">You do not have access.</Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.contextType !== '' &&
          (this.state.orgid === process.env.REACT_APP_DEVELOPMENT_ORG_ID ||
            this.state.orgid === process.env.REACT_APP_PRODUCTION_ORG_ID)
          ? this.showPage()
          : this.showNoAccess()}
      </React.Fragment>
    );
  }
}

export default App;
