import React, { Component, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { CASE_FIELD_CLAIMS } from '../constants'
import "react-calendar/dist/Calendar.css";

class ContractProduct extends Component {
  state = {
    isBrandValidated: false,
    isBrandValid: null,
    isModelValidated: false,
    isModelValid: null,
    isSerialValidated: false,
    isSerialValid: null,
    isSubcategoryValidated: false,
    isSubcategoryValid: null,
  };

  render() {
    const {
      handleInputChange,
      handleInstallDtChange,
      handlePurchaseDtChange,
      installDt,
      isNewBrandValid,
      isNewBrandValidated,
      isInstallDtValid,
      isInstallDtValidated,
      isNewModelValid,
      isNewModelValidated,
      isNewSerialValid,
      isNewSerialValidated,
      isNewSubcategoryValid,
      isNewSubcategoryValidated,
      isPurchaseDtValid,
      isPurchaseDtValidated,
      manufacturers,
      newBrand,
      newModel,
      newSerial,
      newSubcategory,
      purchaseDt,
      subcategories,
      models,
      caseNumberOne,
      caseNumberTwo
    } = this.props;

    return (
      <Fragment>
        <h2>Product</h2>
        <Form.Group as={Row} controlId="purchaseDt">
          <Form.Label column xs={6}>
            Purchase Date:
          </Form.Label>
          <br></br>
          <Col xs={6}>
            <DatePicker
              calendarType="US"
              className={
                !isPurchaseDtValidated
                  ? null
                  : isPurchaseDtValid
                  ? "is-valid"
                  : "is-invalid"
              }
              clearIcon={null}
              dayPlaceholder="DD"
              maxDate={new Date()}
              monthPlaceholder="MM"
              onChange={(date) => handlePurchaseDtChange(date)}
              required={true}
              showLeadingZeros={true}
              value={purchaseDt}
              yearPlaceholder="YYYY"
            />
          </Col>
          <Form.Control.Feedback type="invalid">
            Please provide a valid purchase date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Row} controlId="installDt">
          <Form.Label column xs={6}>
            Install Date:
          </Form.Label>
          <br></br>
          <Col xs={6}>
            <DatePicker
              calendarType="US"
              className={
                !isInstallDtValidated
                  ? null
                  : isInstallDtValid
                  ? "is-valid"
                  : "is-invalid"
              }
              clearIcon={null}
              dayPlaceholder="DD"
              maxDate={new Date()}
              monthPlaceholder="MM"
              onChange={(date) => handleInstallDtChange(date)}
              required={true}
              showLeadingZeros={true}
              value={installDt}
              yearPlaceholder="YYYY"
            />
          </Col>
          <Form.Control.Feedback type="invalid">
            Please provide a valid install date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Row} controlId="newBrand">
          <Form.Label column xs={6}>
            Manufacturer:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              autoComplete="off"
              className={
                !isNewBrandValidated
                  ? null
                  : isNewBrandValid
                  ? "is-valid"
                  : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              ref={(element) => (this.newBrandInput = element)}
              size="sm"
              value={newBrand}
            >
              <option value="">Select manufacturer</option>
              {manufacturers?.map((mfg) => (
                <option key={mfg?.brand} value={mfg?.brand?.toUpperCase()}>
                  {mfg?.brand?.toUpperCase()}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a manufacturer.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="newSubcategory">
          <Form.Label column xs={6}>
            Subcategory:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              autoComplete="off"
              className={
                !isNewSubcategoryValidated
                  ? null
                  : isNewSubcategoryValid
                  ? "is-valid"
                  : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              ref={(element) => (this.newSubcategoryInput = element)}
              size="sm"
              value={newSubcategory?.toUpperCase()}
            >
              <option value="">Select subcategory</option>
              {subcategories.length > 0
                ? subcategories?.map((subcategory) => (
                    <option
                      key={`${subcategory.subCategoryCode.toUpperCase()}${subcategory.productSubCategoryDescription?.toUpperCase()}`}
                      value={subcategory.productSubCategoryDescription.toUpperCase()}
                    >
                      {subcategory.productSubCategoryDescription.toUpperCase()}
                    </option>
                  ))
                : null}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a subcategory.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="newModel">
          <Form.Label column xs={6}>
            Model Number:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as={models.length > 0 ? "select" : "input"}
              autoComplete="off"
              className={
                !isNewModelValidated
                  ? null
                  : isNewModelValid
                  ? "is-valid"
                  : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              ref={(element) => (this.newModelInput = element)}
              placeholder="Model Number"
              size="sm"
              value={newModel}
            >
              {
                models.length > 0 ? (
                  <>
                    <option value="">Select model number</option>
                    {
                      models?.map((model) => (
                        <option
                          key={`${model.modelNumber}`}
                          value={model.modelNumber}
                        >
                          {model.modelNumber}
                        </option>
                      ))
                    }
                  </>
                ) : null
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a model number.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="newSerial">
          <Form.Label column xs={6}>
            Serial:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isNewSerialValidated
                  ? null
                  : isNewSerialValid
                  ? "is-valid"
                  : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="Serial"
              ref={(element) => (this.newSerialInput = element)}
              size="sm"
              type="text"
              value={newSerial}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a serial.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {
          CASE_FIELD_CLAIMS.includes(newBrand) && (
            <>
              <Form.Group as={Row} controlId="caseNumberOne">
                <Form.Label column xs={6}>
                  Case #1:
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    as="input"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    ref={(element) => (this.caseNumberOneInput = element)}
                    placeholder="Case 1"
                    size="sm"
                    value={caseNumberOne}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="caseNumberTwo">
                <Form.Label column xs={6}>
                  Case #2:
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    as="input"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    ref={(element) => (this.caseNumberTwoInput = element)}
                    placeholder="Case 2"
                    size="sm"
                    value={caseNumberTwo}
                  />
                </Col>
              </Form.Group>
            </>
          )
        }
      </Fragment>
    );
  }
}

export default ContractProduct;
