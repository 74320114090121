import React, { Component, Fragment } from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import parse from "html-react-parser";

class Contract extends Component {
  getEffectiveDtStyling() {
    return this.props.isEffective ? "" : "text-danger fw-bold";
  }

  getStatusStyling() {
    return this.props.contractStatusCode !== "A" ? "text-danger fw-bold" : "";
  }

  render() {
    const {
      contractNumber,
      contractStatus,
      dealerName,
      deductible,
      deductibleTerm,
      effectiveDt,
      expirationDt,
      lolAvailable,
      lolUsed,
      selectedContract,
      selectedProduct,
      totalLiability,
    } = this.props;

    const lolUsedMoneyFormat =
      parseFloat(lolUsed) > 0 ? "$" + parseFloat(lolUsed).toFixed(2) : "$0";
    const lolAvailableMoneyFormat =
      parseFloat(lolAvailable) > 0
        ? "$" + parseFloat(lolAvailable).toFixed(2)
        : "$0";
    const totalLiabilityMoneyFormat =
      parseFloat(totalLiability) > 0
        ? "$" + parseFloat(totalLiability).toFixed(2)
        : "$0";

    return (
      <Fragment>
        <Row>
          <Col>
            <h2>Contract Number: {contractNumber}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>{selectedContract.coverage.description.substring(0, 50)}</h4>
          </Col>
          <Col md={{ span: 3, offset: 3 }}>
            {deductible > 0 && (
              <Alert variant="success">
                {parse(
                  `<strong>Deductible required:</strong> $${deductible} ${deductibleTerm}`
                )}
              </Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Effective Date</th>
                  <th>Expiration Date</th>
                  <th>Dealer</th>
                  <th>LOL Used</th>
                  <th>LOL Availability</th>
                  <th>Total Liability</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ cursor: "initial" }}>
                  <td>
                    <span className={this.getStatusStyling()}>
                      {contractStatus}
                    </span>
                  </td>
                  <td>
                    <span className={this.getEffectiveDtStyling()}>
                      {effectiveDt}
                    </span>
                  </td>
                  <td>{expirationDt}</td>
                  <td>{dealerName}</td>
                  <td>{selectedProduct ? lolUsedMoneyFormat : ""}</td>
                  <td>{selectedProduct ? lolAvailableMoneyFormat : ""}</td>
                  <td>{selectedProduct ? totalLiabilityMoneyFormat : ""}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Contract;
