import React, { Component, Fragment } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Service extends Component {
  render() {
    const failureTypeList = ['Standard', 'Accidental Damage', 'Theft'];
    const procedureCodeList = ['Field Service', 'Depot', 'Carry In'];
    const serviceElevationList = ['Normal', 'High'];
    const serviceNoticeList = ['First Notice', 'Repeat Request', 'Armadillo'];
    const serviceTypeList = [
      'Repair',
      'Install',
      'Maintenance',
      'Standard Exchange',
      'Advanced Exchange',
      'Outbound Shipment Only',
    ];

    const bookingTypeList = ['Standard', 'Forced'];
    const warrantyTypeList = [
      'Service Contract',
      'In Warranty',
      'Out of Warranty',
    ];
    const functionalityStatusList = [
      'Functional',
      'Non-functional',
      'Intermittent',
    ];
    const {
      bookingType,
      failureNote,
      failureDt,
      failureType,
      functionalityStatus,
      handleFailureDateSelect,
      handleInputChange,
      isFailureNoteValid,
      isFailureNoteValidated,
      isSymptomCodeValid,
      isSymptomCodeValidated,
      procedureCode,
      serviceElevation,
      serviceNotice,
      servicerNotes,
      serviceOrderNumber,
      serviceType,
      symptomCode,
      symptomCodeList,
      warrantyType,
    } = this.props;

    return (
      <Fragment>
        <h2>Service Information</h2>
        <Form.Group as={Row} controlId="serviceOrderNumber">
          <Form.Label column xs={6}>
            Service Order Number:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              plaintext
              readOnly
              defaultValue={serviceOrderNumber}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="serviceElevation">
          <Form.Label column xs={6}>
            Elevation:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={serviceElevation}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {serviceElevationList.map((elevation, i) => (
                <option key={i} value={elevation}>
                  {elevation}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="serviceNotice">
          <Form.Label column xs={6}>
            Notice:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={serviceNotice}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {serviceNoticeList.map((notice, i) => (
                <option key={i} value={notice}>
                  {notice}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="warrantyType">
          <Form.Label column xs={6}>
            Warranty Type:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={warrantyType}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {warrantyTypeList.map((warranty, i) => (
                <option key={i} value={warranty}>
                  {warranty}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="serviceType">
          <Form.Label column xs={6}>
            Service Type:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={serviceType}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {serviceTypeList.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="bookingType">
          <Form.Label column xs={6}>
            Booking Type:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={bookingType}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {bookingTypeList.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="procedureCode">
          <Form.Label column xs={6}>
            Procedure Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={procedureCode}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {procedureCodeList.map((code) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="functionalityStatus">
          <Form.Label column xs={6}>
            Functionality Status:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={functionalityStatus}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {functionalityStatusList.map((functionality) => (
                <option key={functionality} value={functionality}>
                  {functionality}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureType">
          <Form.Label column xs={6}>
            Failure Type:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={failureType}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {failureTypeList.map((failureType) => (
                <option key={failureType} value={failureType}>
                  {failureType}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="symptomCode">
          <Form.Label column xs={6}>
            Symptom Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              className={
                !isSymptomCodeValidated
                  ? null
                  : isSymptomCodeValid
                    ? 'is-valid'
                    : 'is-invalid'
              }
              as="select"
              value={symptomCode}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              <option value="">Select symptom code...</option>
              {symptomCodeList.map((symptomCode) => (
                <option
                  key={symptomCode.description}
                  value={symptomCode.description}
                >
                  {symptomCode.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a symptom code.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureDt">
          <Form.Label column xs={6}>
            Failure Date:
          </Form.Label>
          <Col xs={6}>
            <DatePicker
              as={Form.Control}
              selected={new Date()}
              onSelect={(date) => handleFailureDateSelect(date)}
              value={failureDt}
              maxDate={new Date()}
              disabledKeyboardNavigation
              aria-describedby="failureDtHelpBlock"
            />
            <Form.Text id="failureDtHelpBlock" muted>
              <br></br>If unknown, use today's date.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureNote">
          <Form.Label column xs={6}>
            Complaint:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="textarea"
              placeholder="failure note"
              value={failureNote}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
              className={
                !isFailureNoteValidated
                  ? null
                  : isFailureNoteValid
                    ? 'is-valid'
                    : 'is-invalid'
              }
            />
            <Form.Control.Feedback type="invalid">
              Please provide the complaint.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="servicerNotes">
          <Form.Label column xs={6}>
            Servicer Notes:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="textarea"
              placeholder="notes"
              value={servicerNotes}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
      </Fragment>
    );
  }
}

export default Service;
