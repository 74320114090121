import React, { Component, Fragment } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";

class LossCodeList extends Component {
  getVariant = (code) => {
    const { selectedLossCode } = this.props;

    if (
      selectedLossCode?.code === code?.code &&
      selectedLossCode != null &&
      selectedLossCode !== ""
    ) {
      return "table-active";
    } else {
      return this.code === null ? "table-light" : null;
    }
  };

  getChecked = (code) => {
    const { selectedLossCode } = this.props;
    return selectedLossCode?.code === code?.code &&
      selectedLossCode != null &&
      selectedLossCode !== ""
      ? "checked"
      : null;
  };

  renderLossCodes = () => {
    const { lossCodeList, handleLossCodeSelect } = this.props;

    return lossCodeList.map((code) => {
      return (
        <tr
          key={code.code}
          className={this.getVariant(code)}
          onClick={() => handleLossCodeSelect(code)}
        >
          <td>
            <Form.Check
              type="radio"
              aria-label={code.code}
              label={code.description.substring(0, 50)}
              checked={this.getChecked(code)}
              readOnly
            />
          </td>
        </tr>
      );
    });
  };
  render() {
    const { loading, lossCodeList } = this.props;

    return (
      <Fragment>
        {loading && (
          <Row>
            <Col>
              <Spinner animation="border"></Spinner>
              <br />
              <span className="spinner-text">Re-loading loss code list...</span>
            </Col>
          </Row>
        )}
        {!loading && (
          <Table>
            <tbody>
              {lossCodeList.length > 0 ? (
                this.renderLossCodes()
              ) : (
                <tr>
                  <td>No loss codes available.</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Fragment>
    );
  }
}

export default LossCodeList;
